import React from 'react';
import {Link, graphql} from 'gatsby';
import StaticLayout from 'components/layouts/static-layout';
import Bio from 'components/bio';
import {
  Card,
  CardBody,
  CardTitle,
  ColorBox,
  Container,
  P,
  Row,
  Wave,
} from 'components/wrappers';
import PropTypes from 'prop-types';
import {HEADER_BG_COLOR, HERO_BG_COLOR, HERO_COLOR} from 'assets/theme';

const Blog = ({data, location}) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <StaticLayout
      {...{
        bgColor: HERO_BG_COLOR,
        location,
        seo: {title: 'All posts'},
      }}
    >
      <ColorBox bgColor={HERO_BG_COLOR} color={HERO_COLOR}>
        <Container hero>
          <h1>Blog</h1>
          <P l>Hot off the press</P>
        </Container>
      </ColorBox>
      <Wave bgColor={HERO_BG_COLOR} />
      <ColorBox bgColor={HEADER_BG_COLOR} color={HERO_COLOR}>
        <Container heroPadding>
          <Row>
            <div className="col-lg-8">
              {posts.map(({node}) => {
                const title = node.frontmatter.title || node.fields.slug;
                return (
                  <article key={node.fields.slug}>
                    <Card>
                      <CardTitle>
                        <h2>
                          <Link to={node.fields.slug}>{title}</Link>
                        </h2>
                        <small>{node.frontmatter.date}</small>
                      </CardTitle>
                      <CardBody>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: node.frontmatter.description || node.excerpt,
                          }}
                        />
                      </CardBody>
                    </Card>
                  </article>
                );
              })}
            </div>
            <div className="col-lg-4">
              <Bio />
            </div>
          </Row>
        </Container>
      </ColorBox>
      <Wave bgColor={HERO_BG_COLOR} flip />
      <br />
    </StaticLayout>
  );
};

Blog.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
    allMarkdownRemark: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Blog;

export const GATSBY_QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
